import { USER_REQUEST, USER_ERROR, USER_SUCCESS, SET_USER_STATUS } from "../actions/user";
import api from "@/services/api"
import { AUTH_LOGOUT } from "../actions/auth";

const state = { status: "", profile: {} };

const getters = {
  getProfile: state => state.profile,
  isProfileLoaded: state => !!state.profile.name
};

const actions = {
  [USER_REQUEST]: ({ commit, dispatch }) => {
    commit(USER_REQUEST);
    api({ url: "/auth/user", methods: 'GET' })
      .then(resp => {
        commit(USER_SUCCESS, resp);
      })
      .catch(() => {
        commit(USER_ERROR);
        // if resp is unauthorized, logout, to
        dispatch(AUTH_LOGOUT);
      });
  },
  [SET_USER_STATUS]: ({commit}, status)=>{
    commit(SET_USER_STATUS, status)
  }

};

const mutations = {
  [USER_REQUEST]: state => {
    state.status = "loading";
  },
  [USER_SUCCESS]: (state, resp) => {
    state.status = "success";
    state.profile = resp;
  },
  [USER_ERROR]: state => {
    state.status = "error";
  },
  [AUTH_LOGOUT]: state => {
    state.profile = {};
  },
  [SET_USER_STATUS]: (state, status) => {
    state.status = status;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
